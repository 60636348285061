import { apiClient2 } from '@/apis/clients';
// import { objectToQuerystring } from '@/apis/helpers';

const academyPrefix = '/education-api/v0';

export const api = {
  // [GET] 나의 실무교육 신청 내역
  async getEnrollments() {
    try {
      let queryString = '?filter-passed-course=true';
      const result = await apiClient2.get(`${academyPrefix}/enrollments` + queryString);
      return result;
    } catch (e) {
      throw Error(e.title);
    }
  },

  // [POST] 실무교육 신청
  async applyAcademy({ courseId }) {
    try {
      const result = await apiClient2.post(`${academyPrefix}/enrollments`, { courseId });
      return result?.data;
    } catch (e) {
      throw Error(e.title);
    }
  },

  // [POST] 실무교육 결제하기 STEP1
  async payAcademyFeeStepOne({ enrollmentId }) {
    try {
      const result = await apiClient2.post(`${academyPrefix}/bills`, { enrollmentId });
      return result?.data;
    } catch (e) {
      throw Error(e.title);
    }
  },

  // [POST] 실무교육 결제하기 STEP2
  async payAcademyFeeStepTwo({ billId, providerKey, recommendation }) {
    try {
      const result = await apiClient2.post(`${academyPrefix}/bills/${billId}/payment`, {
        providerKey,
        recommendation,
      });
      return result?.data;
    } catch (e) {
      throw Error(e.title);
    }
  },

  // [PUT] 실무교육 일정 변경
  async editAcademy({ enrollmentId, courseId }) {
    try {
      const result = await apiClient2.put(`${academyPrefix}/enrollments/${enrollmentId}/course`, { courseId });
      return result?.data;
    } catch (e) {
      throw Error(e.type);
    }
  },

  // [GET] 실무교육 결제 내역
  async getBill({ enrollmentId }) {
    let queryString = ``;
    if (enrollmentId) {
      queryString = `?enrollment-id=${enrollmentId}&status=PAYMENT-DONE`;
    } else {
      queryString = `?status=PAYMENT-DONE`;
    }
    try {
      const result = await apiClient2.get(`${academyPrefix}/bills` + queryString);
      return result?.data;
    } catch (e) {
      throw Error(e);
    }
  },

  // [POST] 실무교육 결제 취소
  async cancelAcademy({ billId }) {
    try {
      const result = await apiClient2.post(`${academyPrefix}/bills/${billId}/refund`, '');
      return result?.data;
    } catch (e) {
      throw Error(e.type);
    }
  },

  // [POST] 실무교육 신청 로깅
  async applyAcademyLog() {
    try {
      const result = await apiClient2.post(`${academyPrefix}/applicants`);
      return result?.data;
    } catch (e) {
      throw Error(e.title);
    }
  },
};
