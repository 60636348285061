import { priceToText } from '@/pages/Buying/util';
import React from 'react';
import Html from '../../../../combiendComponents/Html';

const Contents = ({ feedData, detail_item }) => {
  return (
    <>
      {!detail_item || detail_item.feedId ? (
        <Html contents={feedData?.contents} />
      ) : (
        <div className="ch-item">
          <span className="ch-info-name">{detail_item.complex_name}</span>
          <span className="ch-info-type">
            {detail_item.pyeong}평 {detail_item.trade_type} {priceToText(detail_item.price1)}
          </span>
        </div>
      )}
    </>
  );
};

export default Contents;
