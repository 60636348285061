import * as AT from './actionTypes';
import { failure, loading, success } from '@/data/utils';

//검색
export const brokerKeywordSearch = (keyword) => ({
  type: AT.GOODS_KEYWORD_SEARCH,
  keyword,
});
export const brokerSearchLoading = () => ({
  type: AT.GOODS_SEARCH_LOADING,
});
export const brokerSearchSuccess = (goodsList) => ({
  type: AT.GOODS_SEARCH_SUCCESS,
  goodsList,
});
export const brokerSearchFailure = (error) => ({
  type: AT.GOODS_SEARCH_FAILURE,
  error,
});

//검색 조건
export const changeGubun = (gubun) => ({
  type: AT.GOODS_CHANGE_GUBUN,
  gubun,
});
/*
export const changeSearchWord = keyword => ({
  type: AT.CHANGE_KEYWORD_SEARCH,
  keyword
});
*/

export const changeCategory = (category) => ({
  type: AT.GOODS_CHANGE_CATEGORY,
  category,
});

export const changeTradetype = (tradetype) => ({
  type: AT.GOODS_CHANGE_TRADETYPE,
  tradetype,
});

export const changeAreasize = (areasize) => ({
  type: AT.GOODS_CHANGE_AREASIZE,
  areasize,
});

export const changePrice = (price) => ({
  type: AT.GOODS_CHANGE_PRICE,
  price,
});
export const changePrice2 = (price2) => ({
  type: AT.GOODS_CHANGE_PRICE2,
  price2,
});

export const changeStatus = (status) => ({
  type: AT.GOODS_CHANGE_STATUS,
  status,
});
export const changeBrokerageType = (brokerageType) => ({
  type: AT.GOODS_CHANGE_BROKERAGE_TYPE,
  brokerageType,
});

export const changePage = (page) => ({
  type: AT.GOODS_CHANGE_PAGE,
  page,
});
export const changeSearchWord = (keyword) => ({
  type: AT.GOODS_CHANGE_KEYWORD,
  keyword,
});

export const refreshSearchCondition = () => ({
  type: AT.GOODS_REFRESH_SEARCH_CONDITION,
});

export const setNextData = (yn) => ({
  type: AT.SET_NEXT_GOODS_DATA,
  yn,
});
export const setCurrentPage = (page) => ({
  type: AT.SET_GOODS_CURRENT_PAGE,
  page,
});
export const setTotalCnt = (totalCnt) => ({
  type: AT.SET_TOTAL_CNT,
  totalCnt,
});

export const getCallReport = (goods_common_idx, setContactHistory) => ({
  type: AT.GET_CALL_REPORT,
  goods_common_idx,
  setContactHistory,
});

export const getBrokerComment = (goods_common_idx) => ({
  type: AT.GET_BROKER_COMMENT,
  goods_common_idx,
});
export const getBrokerCommentLoading = () => ({
  type: AT.GET_BROKER_COMMENT_LOADING,
});
export const getBrokerCommentSuccess = (commentList) => ({
  type: AT.GET_BROKER_COMMENT_SUCCESS,
  commentList,
});
export const getBrokerCommentFailure = () => ({
  type: AT.GET_BROKER_COMMENT_FAILURE,
});
export const getGoodsHistory = (goods_common_idx) => ({
  type: AT.GET_GOODS_HISTORY,
  goods_common_idx,
});
export const getGoodsHistoryLoading = () => ({
  type: AT.GET_GOODS_HISTORY_LOADING,
});
export const getGoodsHistorySuccess = (historyList) => ({
  type: AT.GET_GOODS_HISTORY_SUCCESS,
  historyList,
});
export const getGoodsHistoryFailure = () => ({
  type: AT.GET_GOODS_HISTORY_FAILURE,
});
export const getGoodsMemo = (goods_common_idx) => ({
  type: AT.GET_MANAGE_GOODS_MEMO,
  goods_common_idx,
});
export const getGoodsMemoLoading = () => ({
  type: AT.GET_MANAGE_GOODS_MEMO_LOADING,
});
export const getGoodsMemoSuccess = (memoList) => ({
  type: AT.GET_MANAGE_GOODS_MEMO_SUCCESS,
  memoList,
});
export const getGoodsMemoFailure = () => ({
  type: AT.GET_MANAGE_GOODS_MEMO_FAILURE,
});
export const getGoodsList = (payload) => ({
  type: AT.GET_MANAGE_GOODS_LIST,
  payload,
});
export const getGoodsListLoading = () => ({
  type: AT.GET_MANAGE_GOODS_LIST_LOADING,
});
export const getGoodsListSuccess = (goodsList) => ({
  type: AT.GET_MANAGE_GOODS_LIST_SUCCESS,
  goodsList,
});
export const getGoodsListFailure = () => ({
  type: AT.GET_MANAGE_GOODS_LIST_FAILURE,
});
export const setGoodsListCount = (goodsListCount) => ({
  type: AT.SET_MANAGE_GOODS_LIST_COUNT,
  goodsListCount,
});
export const scrollPosition = (scroll) => ({
  type: AT.SET_MANAGE_GOODS_SCROLL_POSITION,
  scroll,
});

export const getGoodsDetail = (goods_common_idx) => ({
  type: AT.GET_MANAGE_GOODS_DETAIL,
  goods_common_idx,
});
export const getGoodsDetailFailure = () => ({
  type: AT.GET_MANAGE_GOODS_DETAIL_FAILURE,
});
export const getGoodsDetailLoading = () => ({
  type: AT.GET_MANAGE_GOODS_DETAIL_LOADING,
});
export const resetGoodsDetail = () => ({
  type: AT.RESET_GOODS_DETAIL,
});
export const removeNew = (goods_idx) => ({
  type: AT.REMOVE_GOODS_NEW,
  goods_idx,
});

export const brokerComment = (param) => ({
  type: AT.MANAGE_GOODS_BROKER_COMMENT,
  param,
});
export const memo = (param) => ({
  type: AT.MANAGE_GOODS_MEMO,
  param,
});
export const setGoodsCommonDetail = (data) => ({
  type: AT.SET_GOODS_COMMON_DETAIL,
  data,
});
export const setGoodsDetail = (status, goodsDetail) => ({
  type: AT.SET_MANAGE_GOODS_DETAIL,
  status,
  goodsDetail,
});

export const setCallReport = (list) => ({
  type: AT.SET_CALL_REPORT,
  list,
});

export const searchSuccess = (goodsList) => ({
  type: AT.GOODS_SEARCH_SUCCESS,
  goodsList,
});

export const setKeywordSearchResult = (keywordList) => ({
  type: AT.GOODS_KEYWORD_SEARCH_RES,
  keywordList,
});

export const brokerFavorite = (goods_common_idx) => ({
  type: AT.GOODS_FAVORITE_DESIGNATE,
  goods_common_idx,
});
export const brokerFavoriteCancel = (goods_common_idx) => ({
  type: AT.GOODS_FAVORITE_DE_DESIGNATE,
  goods_common_idx,
});
export const fakeSaleReport = (param) => ({
  type: AT.FAKE_SALE_REPORT,
  param,
});
export const setFakeSaleReport = (res) => ({
  type: AT.SET_FAKE_SALE_REPORT_RES,
  res,
});

export const setContractInfo = (payload) => ({
  type: AT.SET_CONTRACT_INFO,
  payload,
});
export const setContractDetails = (detailType, index, key, value) => ({
  type: AT.SET_CONTRACT_DETAILS,
  detailType,
  index,
  key,
  value,
});
export const setContractMembers = (memberType, index, key, value) => ({
  type: AT.SET_CONTRACT_MEMBERS,
  memberType,
  index,
  key,
  value,
});
export const setContractMember = (member) => ({
  type: AT.SET_CONTRACT_MEMBER,
  member,
});
export const setContractInfoIni = () => ({
  type: AT.SET_CONTRACT_INFO_INI,
});

export const contractInfoSave = (goContractDetail) => ({
  type: AT.CONTRACT_INFO_SAVE,
  goContractDetail,
});
export const contractInfoExSave = (setViewOn) => ({
  type: AT.CONTRACT_INFO_EX_SAVE,
  setViewOn,
});
export const contractInfoEdit = (temp) => ({
  type: AT.CONTRACT_INFO_EDIT,
  temp,
});

//======================================
//중개사마이 - 중개사매물정보변경
export const modifyGoods = ({ priceInputs, tradeTypes, callback }) => ({
  type: AT.MODIFY_GOODS,
  priceInputs,
  tradeTypes,
  callback,
});
export const changeModifyGoods = (changetype, val) => ({
  type: AT.CHANGE_MODIFY_GOODS,
  changetype,
  val,
});
export const setModifyGoodsIni = () => ({
  type: AT.SET_CONTRACT_INFO_INI,
});

export const contractInfoSaveRes = (contractInfoSaveRes) => ({
  type: AT.CONTRACT_INFO_SAVE_RES,
  contractInfoSaveRes,
});

export const setContractPreType = (client_type) => ({
  type: AT.SET_CONTRACT_PRE_CLIENT_TYPE,
  client_type,
});
export const getAssociatedCommunity = (feedsId) => ({
  type: AT.GET_ASSOCIATED_COMMUNITY,
  feedsId,
});
export const getAssociatedCommunitySuccess = (data) => ({
  type: AT.GET_ASSOCIATED_COMMUNITY_SUCCESS,
  data,
});

export const initialize = () => ({
  type: AT.INITIALIZE,
});
