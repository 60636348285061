import { TagType } from '@/services/Community';
import React from 'react';

const HashTags = ({ feedData, detail_item }) => {
  const tags = feedData?.tags;

  const address = tags
    ?.filter((item) => !!item.name && item.type === TagType.ADDRESS_TAG)
    .map((item) => `#${item.name}`)
    .join(' ');

  const goodsTypes = tags
    ?.filter((item) => !!item.name && item.type === TagType.GOODS_TYPE_TAG)
    .map((item) => `#${item.name}`)
    .join(' ');

  const realtyTypes = tags
    ?.filter((item) => !!item.name && item.type === TagType.SALE_TYPE_TAG)
    .map((item) => `#${item.name}`)
    .join(' ');

  const coplex = tags
    ?.filter((item) => !!item.name && item.type === TagType.COMPLEX_TAG)
    .map((item) => `#${item.name}`)
    .join(' ');

  const extraHashTags = tags
    ?.filter((item) => !!item.name && item.type === TagType.HASH_TAG)
    .map((item) => `#${item.name}`)
    .join(' ');

  return (
    <div className="posts-tags">
      <p className="data-list">
        {!detail_item || detail_item.feedId ? (
          <span className="data-item">
            {`${address ? address : ''} ${goodsTypes ? goodsTypes : ''} ${realtyTypes ? realtyTypes : ''} ${coplex ? coplex : ''} ${
              extraHashTags ? extraHashTags : ''
            }`}
          </span>
        ) : (
          <>
            <span className="data-item">{`#${detail_item?.dong_name}`}</span>
            <span className="data-item">{`#${detail_item?.complex_name}`}</span>
            <span className="data-item">{`#${detail_item?.pyeong}`}평</span>
            <span className="data-item">{`#${detail_item?.trade_type}`}</span>
          </>
        )}
      </p>
    </div>
  );
};

export default HashTags;
