import { apiClient, kakaoClient } from '@/apis/clients';
import { objectToQuerystring } from '@/apis/helpers';
import apis from '..';

export const api = {
  async search(data) {
    try {
      // return await apiClient.get('/apartment/cached' + objectToQuerystring(data));
      return await apiClient.get('/apartment' + objectToQuerystring(data));
    } catch (e) {
      throw Error(e.message);
    }
  },

  async searchGoods(payload) {
    try {
      return await apiClient.get('/map/goods' + objectToQuerystring(payload));
    } catch (e) {
      throw Error(e.message);
    }
  },
  // async getNewHighList(category, data) {
  //   try {
  //     if (category === 'apt') {
  //       return await apiClient.get('/apartment/getComplexReportPriceList' + objectToQuerystring(data));
  //     } else if (category === 'oft') {
  //       return await apiClient.get('/officetel' + objectToQuerystring(data));
  //     } else {
  //       return [];
  //     }
  //   } catch (e) {
  //     throw Error(e.message);
  //   }
  // },
  async surroundingItem(keyword, category, bounds) {
    try {
      const query = encodeURI('query=' + keyword + '&category_group_code=' + category + '&rect=' + bounds.join(','));
      const result = await kakaoClient.get('v2/local/search/category.json?' + query);
      return result.data.documents;
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getDetail(complexId) {
    try {
      return await apiClient.get('/apartment/' + complexId);
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getComplexDetailBulk(complexIds) {
    try {
      return await apiClient.post('/apartment/bulk', { complexIds: complexIds });
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getAreaList(complexIdx) {
    try {
      return await apiClient.get('/apartment/' + complexIdx + '/pyeong');
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getTradeHistory(complexId, areaNo, tradtype, year) {
    try {
      const data = objectToQuerystring({ areaNo, tradtype, beyear: year ? year : 3 });
      return await apiClient.get('/apartment/' + complexId + '/trade-history' + data);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // async getTrade(complexId, areaNo, page, tradtype) {
  async getTrade(complexId, payload) {
    try {
      // return await apiClient.get('/apartment/' + complexId + '/trade?areaNo=' + areaNo + '&page=' + page + '&tradtype=' + tradtype + '&category=APT');
      return await apiClient.get(`/apartment/${complexId}/trade${objectToQuerystring(payload)}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  /**
   * 단지 실거래가 요약정보
   * @see https://dawinsoft.atlassian.net/wiki/spaces/DEV/pages/87916621/API
   * areaNo - isPyeong에 따라 area_no 혹은 평수
   * isPyeong - 0 : areaNo를 실제 해당 단지의 area_no로 사용,
   *            1 : areaNo를 평수로 사용
   */
  async getRecentlyTrade(complexId, payload) {
    try {
      return await apiClient.get(`/apartment/${complexId}/summary${objectToQuerystring(payload) + '&isPyeong=0'}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getCharterPrediction(complexId) {
    try {
      return await apiClient.get('/apartment/' + complexId + '/charterPredictionCnt');
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getTax(complexId, areaNo) {
    try {
      return await apiClient.post('/apartment/taxation', { complexIdx: complexId, pyengNo: areaNo });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getBrokerList(complexId) {
    try {
      return await apiClient.post('/apartment/complexBrokerOfficeList', { complexIdx: `${complexId}` });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getSchool(complexId) {
    try {
      return await apiClient.post('/apartment/complexSchoolData', { complexIdx: complexId });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getSchoolDetail(id) {
    try {
      return await apiClient.get('/info/elementary' + objectToQuerystring({ schoolIdx: id }));
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getKindergarten(complexId) {
    try {
      return await apiClient.post('/apartment/complexKindergartenData', { complexIdx: complexId });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getKinderDetail(id) {
    try {
      return await apiClient.get('/info/kindergarten' + objectToQuerystring({ kindergartenIdx: id }));
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getDaycare(complexId) {
    try {
      return await apiClient.post('/apartment/complexDaycareData', { complexIdx: complexId });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getDaycareDetail(daycareIdx) {
    try {
      return await apiClient.get('/info/daycare' + objectToQuerystring({ daycareIdx: daycareIdx }));
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getComplexDetail(complexId) {
    try {
      const url = '/apartment/' + complexId + '/detail';
      return await apiClient.get(url);
    } catch (e) {
      throw Error(e.message);
    }
  },

  /**
   * 네이버매물
   * https://dawinsoft.atlassian.net/wiki/spaces/DEV/pages/1299742727
   *
   * reqType: 가격범위 옵션
   *  - pyeong : 공급평형별로 묶음 (34.25 => 34)
   *  - areaNo : 타입별로 묶음 (34A, 34B..)
   */
  async getMarketData({ complexIdx, reqType = 'pyeong' }) {
    try {
      const url = `/market-data/${complexIdx}/getAreaPrices?reqType=${reqType}`;
      const result = await apiClient.get(url);
      return result;
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getFacilityData({ complexIdx }) {
    try {
      const result = await apiClient.get(`/complex-facility/${complexIdx}`);
      return result;
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getFacilityCode(data) {
    try {
      return await apiClient.get('/complex-facility/code' + objectToQuerystring(data));
    } catch (e) {
      throw Error(e.message);
    }
  },
  async getFacilityDataUser(data) {
    try {
      const { mbrIdx, complexIdx } = data;
      return await apiClient.get(`/complex-facility/user/${mbrIdx}/${complexIdx}`);
    } catch (error) {
      throw Error(error.message);
    }
  },
  async uploadFacilityDataUser(data) {
    try {
      const { mbrIdx, complexIdx, body } = data;
      return await apiClient.post(`/complex-facility/user/${mbrIdx}/${complexIdx}`, body);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 네이버 부동산 단지 매물정보
  async getNaverSales(data, startProxyIndexRaw) {
    try {
      const { complexId } = data;
      // `maxCacheAge` 는 초 단위로 보낸다.
      // 없을 시 default 3시간 (10800 초)
      let maxCacheAge = process.env.NAVER_PROXY_MAX_CACHE_AGE;
      const url = `/naver-proxy/complex/${complexId}?maxCacheAge=${maxCacheAge ?? 10800}&index=${startProxyIndexRaw}`;

      // 2021-02-18 정석규 수정
      // 네이버 프록시 API 호출방식 변경
      // 기존: 화면 -> 프록시
      // 신규: 화면 -> 다윈 API -> 프록시
      // return await proxyClient.get(url);
      return await apiClient.get(url);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 다윈매물과 동일한 네이버 매물정보
  async getSameExternalGoodsList(goodsCommonIdx) {
    return await apiClient.get(`/naver-proxy/goods/${goodsCommonIdx}`);
  },

  // 다윈매물과 유사한 네이버 매물정보
  async getSimilarExternalGoodsList(goodsCommonIdx) {
    return await apiClient.get(`/naver-proxy/goods/${goodsCommonIdx}/similar`);
  },

  //마이페이지 - 매물상세
  async getMySaleDetail(goods_idx) {
    try {
      const url = '/goods/' + goods_idx + '/detail';
      return await apiClient.get(url);
    } catch (e) {
      throw Error(e.message);
    }
  },

  //중개사 상세
  /** @deprecated */
  async getBrokerDetail(broker_office_idx, goods_idx, user) {
    try {
      return await apis.brokerApi.getBrokerDetail(broker_office_idx, goods_idx, user?.mbr_idx);
    } catch (e) {
      throw Error(e.message);
    }
  },

  //중개사에게 문의하기
  async askToBroker({ broker_office_idx, goods_idx, contents }) {
    try {
      return await apiClient.post('/broker/state-question', { broker_office_isx: broker_office_idx, goods_idx: goods_idx, contents });
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getSchoolInfoWithKakao(data) {
    try {
      return await apiClient.get('/info/getSearchData' + objectToQuerystring(data));
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getComplexFacility(complex_idx) {
    try {
      // return await apiClient.get('/info/getSearchData' + objectToQuerystring(data));
    } catch (e) {
      throw Error(e.message);
    }
  },

  async reportComplexFacility(complex_idx, options, text) {
    try {
      // return await apiClient.get('/info/getSearchData' + objectToQuerystring(data));
    } catch (e) {
      throw Error(e.message);
    }
  },

  async getAreasByPyeong(complex_idx, pyeong) {
    try {
      return await apiClient.get(`/complex/${complex_idx}/area?pyeong=${pyeong}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 중개사 리스트 - 지도 기반
  async getBrokersOnMap(data) {
    try {
      /**
       * swLng=127.06079466976614
       * &swLat=37.166846519568685
       * &neLng=127.06321373182895
       * &neLat=37.56973744477513
       * &zoomLevel=2
       * */
      return await apiClient.get(`/broker${objectToQuerystring(data)}`);
    } catch (e) {
      throw Error(e.message);
    }
  },

  // 급매물
  async getQuickSaleGoods({ addressCode, tradeTypes = [], page = 1, perPage = 50, image }) {
    try {
      // addressCode : 전국일 경우 보내지 않음, 11=서울, 41=경기
      // page : default=1
      // perPage : default=50
      // request parameter : tradeTypes 추가 (s|c|m과 같이 요청, 없는 경우 전체조회)
      // image=Y 요청하면 이미지 있는 급매물만 조회
      // reponse에서 매물가격이 기존 tradePrice에서 price1, price2, price3으로 변경됨
      const payload = { page, perPage };
      if (addressCode) payload.addressCode = addressCode;
      if (tradeTypes?.length > 0) payload.tradeTypes = tradeTypes.join('|');
      if (image) payload.image = image;
      return await apiClient.get(`/analysis/recommendedGoods${objectToQuerystring(payload)}`);
    } catch (e) {
      throw Error(e.message);
    }
  },
};
