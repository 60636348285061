import { loadTossPayments } from '@tosspayments/sdk';
import envs from '@/resources/env';
import { dispatch } from 'd3-dispatch';
import * as actions from '@/data/rootActions';

import * as toss from './toss';

export { toss };

export const requestPayment = ({ amount, orderName, customerName, customerEmail, billId }) => {
  loadTossPayments(envs.PAYMENT_CLIENT)
    .then((tossPayments) => {
      tossPayments.requestPayment('카드', {
        amount: amount,
        orderId: billId,
        orderName: orderName,
        customerName: customerName,
        customerEmail: customerEmail,
        maxCardInstallmentPlan: 3,
        successUrl: `${window.location.origin}/payment/academy/success?billId=${billId}&historyLength=${history.length}`,
        failUrl: `${window.location.origin}/payment/academy/fail?billId=${billId}&historyLength=${history.length}`,
      });
    })
    .catch((err) => {
      if (err.code === 'USER_CANCEL') {
        // 취소 이벤트 처리
        //console.log('결제 취소..!');
        // console.log('FAIL', history.length, history.state, history.state.data);

        dispatch(
          actions.common.alert({
            contents: '결제를 취소하셨습니다.',
            onConfirm: () => {
              dispatch(actions.agentSignUp.resetAcademy());
              dispatch(actions.router.replace('/agentSignUp/academy'));
            },
          })
        );
      }
    });
};
